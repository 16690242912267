import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from '../shared.service';

@Pipe({
  name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {

  constructor(
    private shared_service: SharedService
  ){}

  transform(value: number, fixed: number): string {
    return this.shared_service.short_number(value, fixed);
  }

}
