import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';

@Pipe({ name: 'timeAgo', pure: false })
export class TimeAgoPipe extends AsyncPipe implements PipeTransform {
  private value: Date;
  private timer: Observable<string>;

  constructor(ref: ChangeDetectorRef) {
    super(ref);
  }

  transform(value: any): any {
    const date = new Date(value);
    if (!isNaN(date.getTime())) {
      this.value = date;
      if (!this.timer) {
        this.timer = this.getObservable();
      }
      return super.transform(this.timer);
    }
    return super.transform(value);
  }

  private getObservable(): Observable<string> {
    return interval(1000).pipe(
      map(() => {
        const now = new Date();
        const deltaSeconds = Math.floor((now.getTime() - this.value.getTime()) / 1000);
        const deltaDays = Math.floor(deltaSeconds / (60 * 60 * 24));

        let result: string;

        if (deltaDays > 0) {
          if (deltaDays === 1) {
            result = 'yesterday';
          } else if (deltaDays <= 3) {
            result = 'a few days ago';
          } else {
            result = `${deltaDays} days ago`;
          }
        } else {
          if (deltaSeconds <= 60) {
            result = 'just now';
          } else if (deltaSeconds <= 1800) {
            result = 'a few minutes ago';
          } else if (deltaSeconds <= 3600) {
            result = 'half an hour ago';
          } else {
            result = 'today';
          }
        }

        return result;
      })
    );
  }
}


// import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
// import { Observable, timer, map, interval } from 'rxjs';
// import { AsyncPipe } from '@angular/common';

// @Pipe({name: 'timeAgo', pure: false})
// export class TimeAgoPipe extends AsyncPipe {
//   value: Date;
//   timer: Observable<string>;

//   constructor(ref: ChangeDetectorRef) {
//     super(ref);
//   }

//   transform(obj:any):any {
//     obj = new Date(obj);
//     if (obj instanceof Date) {
//       this.value = obj;
//       if(!this.timer) this.timer = this.getObservable();
//       return super.transform(this.timer);
//     }
//     return super.transform(obj);
//   }

//   dateDiffInDays(a, b) {
//     const _MS_PER_DAY = 1000 * 60 * 60 * 24;

//     const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
//     const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    
//     return Math.floor((utc2 - utc1) / _MS_PER_DAY);
//   }

//   private getObservable():Observable<string> {
//     return interval(1000).pipe(
//       map(() => {
//         let result: string;

//         let now_local = new Date();
//         let now =  new Date(now_local.getUTCFullYear(), now_local.getUTCMonth(), now_local.getUTCDate(),
//         now_local.getUTCHours(), now_local.getUTCMinutes(), now_local.getUTCSeconds());

//         let delta_days = Math.ceil((new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime() - 
//         new Date(this.value.getFullYear(), this.value.getMonth(), this.value.getDate()).getTime()) / (1000 * 60 * 60 * 24));

//         console.log(delta_days);

//         if (delta_days > 0) {
//           switch (true) {
//             case (delta_days == 1): { result = 'yesterday'; break; }
//             case (delta_days > 1 && delta_days <= 3): { result = 'few days ago'; break; }
//             default: { result = this.value.toString(); }
//           }
//         } else {
//           let delta_seconds = Math.abs(now.getTime() - this.value.getTime()) / 1000;
//           switch (true) {
//             case (delta_seconds <= 60): { result = 'just now'; break; }
//             case (delta_seconds > 60 && delta_seconds <= 1800): { result = 'few minutes ago'; break; }
//             case (delta_seconds > 1800 && delta_seconds <= 3600): { result = 'half hour ago'; break; }
//             case (delta_seconds > 3600): { result = 'today'; break; }
//           }
//         }

//         // return (result) ? result : this.value.toString();
//         return (result) ? result : '123';
//       })
//     );
//   };
// }