import { Injectable, OnDestroy } from '@angular/core';
import { CanLoad, CanActivate, Router, Route, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, ReplaySubject, throwError, of } from 'rxjs';
import { catchError, takeUntil, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GuardService implements CanActivate, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private router: Router,
    private auth_service: AuthService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (localStorage.getItem('yoola_app:token')) {
      return this.auth_service.check_token()
      .pipe(
        takeUntil(this.destroyed$),
        catchError(() => of(false)),
        map((data: {result: {partner_common: string[]}}) => {
          if (data?.result) {
            localStorage.setItem('yoola_app:scopes', JSON.stringify(data.result));
            return true;
          } else return false;
        })
      );
    } else {
      this.auth_service.close_session();
      return of(false);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
