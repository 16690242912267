import { Injectable, OnDestroy } from '@angular/core';
import { Observable, throwError, ReplaySubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ServiceMessagesService } from '../shared/service-messages.service';
import { DialogConfirmationComponent } from '../shared/components/dialog-confirmation/dialog-confirmation.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, takeUntil, debounceTime, finalize, map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { DialogAddChannelComponent } from '../account/toolbar/dialog-add-channel/dialog-add-channel.component';

@Injectable({
  providedIn: 'root',
})
export class SharedService implements OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public regex_patterns = {
    email:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    security_code: /^\d{5}$/,
    password:
      /^((?=.*[A-Z])(?=.*[0-9]))[-A-z0-9!"#$%&\'()*+,.\/:;<=>?@\[\]^_`{|}~]{8,}$/m,
    phone: /^(\+?\d{7,})$/,
  };

  public colors: string[] = [
    '107, 139, 246',
    '73, 221, 233',
    '124, 91, 246',
    '63, 182, 184',
    '247, 218, 93',
    '233, 129, 72',
    '181, 79, 79',
    '225, 90, 82',
    '174, 122, 95',
    '136, 133, 124',
  ];

  public month_dict: { title: string; value: string }[] = [
    { title: 'January', value: '01' },
    { title: 'February', value: '02' },
    { title: 'March', value: '03' },
    { title: 'April', value: '04' },
    { title: 'May', value: '05' },
    { title: 'June', value: '06' },
    { title: 'July', value: '07' },
    { title: 'August', value: '08' },
    { title: 'September', value: '09' },
    { title: 'October', value: '10' },
    { title: 'November', value: '11' },
    { title: 'December', value: '12' },
  ];

  public min_birthday: Date = new Date(1900, 0, 1);
  public max_birthday: Date = new Date(new Date().getFullYear() - 14, new Date().getMonth(), new Date().getDate());

  constructor(
    private service_messages: ServiceMessagesService,
    private mat_dialog: MatDialog,
    private date_pipe: DatePipe,
    private http: HttpClient
  ) {}

  public confirmation(data): Observable<boolean> {
    let dialog_ref: MatDialogRef<DialogConfirmationComponent> =
      this.mat_dialog.open(DialogConfirmationComponent, {
        data: data,
        disableClose: true,
      });      
    return dialog_ref.afterClosed().pipe(takeUntil(this.destroyed$));
  }

  public currency_symbol(value: string, space: boolean = false): string {
    let symbol: string = null;
    switch (value) {
      case 'USD':
        symbol = '$';
        break;
      case 'EUR':
        symbol = '€';
        break;
      case 'RUB':
        symbol = '₽';
        break;
      default:
        symbol = value;
    }
    return !space ? symbol : `${symbol} `;
  }

  public short_number(num, fixed = 2): string {
    if (num === null) {
      return null;
    }
    if (num === 0) {
      return '0';
    }

    let minus = num < 0 ? -1 : 1;
    num = num * minus;

    fixed = !fixed || fixed < 0 ? 0 : fixed;
    let b = num.toPrecision(2).split('e'),
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3),
      c =
        k < 1
          ? num.toFixed(0 + fixed)
          : (num / Math.pow(10, k * 3)).toFixed(1 + fixed),
      d = c < 0 ? c : Math.abs(c),
      e = d + ['', 'k', 'm', 'b', 't'][k];

    return minus < 0 ? `-${e}` : e;
  }

  public date(v, format): string {
    return this.date_pipe.transform(v, format);
  }

  public color_palette(rgb): string[] {
    const [r, g, b] = rgb.split(',').map(Number);
    const baseColor = { r, g, b };
    const variations = [
      0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1, 0, -0.1, -0.2, -0.3, -0.4,
      -0.5, -0.6, -0.7, -0.8, -0.9, -1,
    ];
    const palette = variations.map((variation) => {
      const color = {
        r: Math.min(255, Math.max(0, Math.round(baseColor.r + variation * 50))),
        g: Math.min(255, Math.max(0, Math.round(baseColor.g + variation * 50))),
        b: Math.min(255, Math.max(0, Math.round(baseColor.b + variation * 50))),
      };

      return `rgb(${color.r}, ${color.g}, ${color.b})`;
    });

    return palette;
  }

  public encode_base64(obj): string {
    let stringToEncode: string;
    if (typeof obj === 'object') {
      stringToEncode = JSON.stringify(obj);
    } else if (typeof obj === 'string') {
      stringToEncode = obj;
    }
    if (stringToEncode.length > 0) {
      return btoa(
        encodeURIComponent(stringToEncode).replace(
          /%([0-9A-F]{2})/g,
          (match, p1) => {
            let code = `0x${p1}`;
            return String.fromCharCode(+code);
          }
        )
      );
    }
  }

  public validate_range(form: FormGroup) {
    let from: Date = form.get('from').value,
      to: Date = form.get('to').value;
    form.get('to').setErrors(null);

    from = from ? new Date(from) : null;
    to = to ? new Date(to) : null;

    if (from && to) {
      if (from.getTime() > to.getTime()) {
        form.get('to').setErrors({ validate_range: { valid: false } });
        return { validate_range: { valid: false } };
      }
    } else {
      if (!from)
        form.get('from').setErrors({ validate_range: { valid: false } });
      if (!to) form.get('to').setErrors({ validate_range: { valid: false } });
    }

    return null;
  }
 
  public dialog_add_channels() {
      this.mat_dialog.open(DialogAddChannelComponent, {
        disableClose: false,
        width: '460px',
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
