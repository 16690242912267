import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fileSize', pure: false })
export class FileSizePipe implements PipeTransform {

  constructor() {}

  transform(num: any): any {
    const thresh = 1024;

    if (Math.abs(num) < thresh) {
      return `${num} B`;
    } else {
      const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      let u = -1;
      const r = 10**1;

      do {
        num /= thresh;
        ++u;
      } while (Math.round(Math.abs(num) * r) / r >= thresh && u < units.length - 1);

      return `${num.toFixed(1)} ${units[u]}`;
    }
  }
}