import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { CustomSnackbarComponent } from './components/custom-snackbar/custom-snackbar.component';
import { LangService } from './lang.service';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceMessagesService  {
  private duration: number = 10000;
  public messages = null;
  private list: string[] = ['en', 'ru', 'uk'];

  private lang = {
    en: {
      undefined: 'Error 🤖 Please try again later or contact support if the issue persists',
      internal_server_error: 'Error 🤖 Please try again later or contact support if the issue persists',
      payment_details_blocked: 'Editing payment details is currently blocked. Please wait until the payment session is finished',
      route_not_found: 'Route not found!',
      email_updated_successfully: 'Ok, your email has been successfully updated! Log in with your new email',
      invalid_email_change_code: 'Invalid email change code',
      duplicate_payment_details: 'Duplicate payment details',
      payment_details_hash_field_is_not_valid: "Payment details hash field is not valid",
      payment_details_is_not_approved: "Payment details is not approved",
      unknown_error: "Unknown error",
      retries_exhausted: "Retries exhausted",
      kyc_already_passed: "KYC already passed",
      kyc_in_progress: "KYC in progress",
      unsupported_file_type: "Unsupported file type",
      failed_to_load_poi: "Failed to load poi",
    },
    ru: {
      undefined: 'Ошибка 🤖 Попробуйте позже или свяжитесь со службой поддержки, если проблема сохраняется',
      internal_server_error: 'Ошибка 🤖 Попробуйте позже или свяжитесь со службой поддержки, если проблема сохраняется',
      payment_details_blocked: 'Редактирование платежных данных в данный момент заблокировано. Подождите, пока сессия оплаты не будет завершена',
      route_not_found: 'Маршрут не найден!',
      email_updated_successfully: 'Ок, ваша электронная почта успешно обновлена! Войдите, используя вашу новую почту',
      invalid_email_change_code: 'Недействительный код смены электронной почты',
      duplicate_payment_details: 'Такие платежные данные уже существуют',
      payment_details_hash_field_is_not_valid: "Поле хеша платежных данных недействительно",
      payment_details_is_not_approved: "Платежные данные не подтверждены",
      unknown_error: "Unknown error",
      retries_exhausted: "Retries exhausted",
      kyc_already_passed: "KYC already passed",
      kyc_in_progress: "KYC in progress",
      unsupported_file_type: "Unsupported file type",
      failed_to_load_poi: "Failed to load poi",
    },
    uk: {
      undefined: 'Помилка 🤖 Спробуйте пізніше або зв\'яжіться з підтримкою, якщо проблема не зникне',
      internal_server_error: 'Помилка 🤖 Спробуйте пізніше або зв\'яжіться з підтримкою, якщо проблема не зникне',
      payment_details_blocked: 'Редагування платіжних даних тимчасово заблоковано. Зачекайте, поки платіжну сесію буде завершено',
      route_not_found: 'Маршрут не знайдено!',
      email_updated_successfully: 'Ок, вашу електронну пошту успішно оновлено! Увійдіть, використовуючи вашу нову пошту',
      invalid_email_change_code: 'Недійсний код зміни електронної пошти',
      duplicate_payment_details: 'Duplicate payment details',
      payment_details_hash_field_is_not_valid: "Поле хешу платіжних даних недійсне",
      payment_details_is_not_approved: "Платіжні дані не підтверджені",
      unknown_error: "Unknown error",
      retries_exhausted: "Retries exhausted",
      kyc_already_passed: "KYC already passed",
      kyc_in_progress: "KYC in progress",
      unsupported_file_type: "Unsupported file type",
      failed_to_load_poi: "Failed to load poi",
    }    
  }

  constructor(
    private snack_bar: MatSnackBar
    ) {
    const ls = localStorage.getItem('yoola_app:lang');
    const nav = navigator['language'].split('-')[0];
    const lang = (ls && this.list.includes(ls)) ? ls : (nav && this.list.includes(nav)) ? nav : 'en';
    this.messages = this.lang[lang];
  }

  public show(data: any): void {
    this.snack_bar.open(data.text, 'Close', { 
      duration: (data.duration) ? data.duration : this.duration,
    });
  }

  public error(err: any): Observable<never> {
    const message = (err.error.reason) ? (this.messages[err.error.reason]) ? 
    this.messages[err.error.reason] : err.error.reason : this.messages.undefined;
    this.show({text: message});
    return throwError(err) as Observable<never>;
  }

  // public show_custom_snackbar(data:any) {
  //   this.snack_bar.openFromComponent(CustomSnackbarComponent, {
  //     data: {
  //       text: data.text,
  //       type: data.type
  //     },
  //     duration: (data.duration) ? data.duration : this.duration,
  //     // horizontalPosition: 'center',
  //     verticalPosition: 'bottom',
  //     panelClass: data.type
  //   })
  // }
  
}
