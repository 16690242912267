<router-outlet></router-outlet>
<div class="social-group">
  <a href="https://telegram.me/yoola_support_bot" target="_blank" class="non-style icon-animation d-none d-xl-block">
    <svg width="92" height="112" viewBox="0 0 92 112" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_970_26962)">
      <circle cx="56" cy="52" r="24" fill="#4E86DD" style="pointer-events: fill;" />
      </g>
      <g clip-path="url(#clip0_970_26962)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M70 52C70 59.732 63.732 66 56 66C48.268 66 42 59.732 42 52C42 44.268 48.268 38 56 38C63.732 38 70 44.268 70 52ZM56.5017 48.3354C55.14 48.9018 52.4185 50.0741 48.3372 51.8522C47.6745 52.1158 47.3273 52.3736 47.2957 52.6257C47.2423 53.0517 47.7758 53.2195 48.5024 53.448C48.6012 53.4791 48.7036 53.5113 48.8086 53.5454C49.5234 53.7777 50.485 54.0496 50.9848 54.0604C51.4383 54.0702 51.9443 53.8832 52.5031 53.4996C56.3164 50.9255 58.2848 49.6244 58.4084 49.5964C58.4955 49.5766 58.6164 49.5517 58.6982 49.6245C58.7801 49.6972 58.772 49.835 58.7634 49.872C58.7105 50.0973 56.6161 52.0444 55.5323 53.0521C55.1944 53.3662 54.9548 53.589 54.9058 53.6399C54.796 53.7539 54.6841 53.8617 54.5766 53.9654C53.9126 54.6055 53.4146 55.0856 54.6042 55.8696C55.1759 56.2463 55.6334 56.5579 56.0898 56.8687C56.5882 57.2081 57.0853 57.5466 57.7285 57.9683C57.8924 58.0757 58.0489 58.1873 58.2014 58.2959C58.7814 58.7095 59.3025 59.081 59.9464 59.0217C60.3204 58.9873 60.7069 58.6355 60.9031 57.5864C61.3669 55.107 62.2786 49.7349 62.4893 47.5211C62.5077 47.3272 62.4845 47.079 62.4658 46.97C62.4472 46.861 62.4082 46.7058 62.2666 46.5909C62.0989 46.4548 61.8399 46.4261 61.7241 46.4281C61.1975 46.4374 60.3896 46.7183 56.5017 48.3354Z" fill="white"/>
      </g>
      <defs>
      <filter id="filter0_d_970_26962" x="0" y="0" width="112" height="112" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="16"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0392157 0 0 0 0 0.0235294 0 0 0 0 0.0901961 0 0 0 0.08 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_970_26962"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_970_26962" result="shape"/>
      </filter>
      <clipPath id="clip0_970_26962">
      <rect width="28" height="28" fill="white" transform="translate(42 38)"/>
      </clipPath>
      </defs>
    </svg>
  </a>
  <a href="https://api.whatsapp.com/send?phone=17864204870&text=Hi" target="_blank" class="icon-animation d-none d-xl-block" style="margin-top: -4rem;">
    <svg width="92" height="112" viewBox="0 0 92 112" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_970_26963)">
      <circle cx="56" cy="52" r="24" fill="#70D072"  style="pointer-events: fill;"/>
      </g>
      <path d="M56.0503 40.3872C49.4635 40.3872 44.103 45.5503 44.1006 51.8956C44.0991 53.9248 44.6503 55.9051 45.6955 57.6494L44 63.613L50.3352 62.0128C52.0975 62.9346 54.0569 63.4154 56.0457 63.414H56.0503C62.6372 63.414 67.9977 58.2502 68 51.9049C68.0015 48.8313 66.7597 45.9382 64.503 43.7635C62.247 41.588 59.247 40.388 56.0503 40.3872ZM56.0503 61.47H56.0465C54.2643 61.47 52.5161 61.0086 50.991 60.1369L50.6271 59.9294L46.8692 60.8786L47.8725 57.3482L47.6364 56.9867C46.6446 55.474 46.1166 53.7044 46.1174 51.8956C46.1197 46.621 50.576 42.3312 56.0542 42.3312C58.7066 42.332 61.2003 43.3284 63.0761 45.1361C64.952 46.9439 65.984 49.3477 65.9825 51.9041C65.9801 57.1787 61.5246 61.47 56.0495 61.47H56.0503ZM61.4983 54.3049C61.1995 54.1617 59.7316 53.4657 59.4575 53.3689C59.1843 53.2737 58.9853 53.2241 58.7863 53.5121C58.5881 53.8001 58.0152 54.4481 57.8418 54.6393C57.6668 54.8313 57.4926 54.8546 57.1938 54.7113C56.895 54.5673 55.9326 54.2639 54.793 53.2837C53.905 52.5219 53.3058 51.5805 53.1316 51.2917C52.9574 51.0045 53.113 50.8489 53.2625 50.7057C53.3964 50.5779 53.5613 50.3704 53.7099 50.2024C53.8586 50.0344 53.9081 49.9144 54.0088 49.7224C54.1079 49.5312 54.0583 49.3624 53.9832 49.2192C53.9081 49.0744 53.312 47.6592 53.0619 47.084C52.8204 46.5235 52.5742 46.5986 52.3907 46.5893C52.2165 46.5815 52.0183 46.5792 51.8178 46.5792C51.6204 46.5792 51.296 46.6512 51.0219 46.9392C50.7486 47.2272 49.9768 47.9224 49.9768 49.3377C49.9768 50.7537 51.0467 52.1209 51.1961 52.3129C51.3455 52.5041 53.3019 55.4097 56.2973 56.6561C57.0095 56.9511 57.5654 57.1284 57.9997 57.2615C58.7151 57.4806 59.3662 57.4489 59.8803 57.3753C60.4532 57.2925 61.647 56.6801 61.8947 56.0089C62.144 55.3377 62.144 54.7617 62.0697 54.6417C61.9969 54.5217 61.7972 54.4497 61.4983 54.3049Z" fill="white"/>
      <defs>
      <filter id="filter0_d_970_26963" x="0" y="0" width="112" height="112" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="16"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0392157 0 0 0 0 0.0235294 0 0 0 0 0.0901961 0 0 0 0.08 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_970_26963"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_970_26963" result="shape"/>
      </filter>
      </defs>
      </svg>
      
  </a>
  <div id="intercom" class="icon-animation" style="cursor: pointer; margin-top: -4rem;">
    <svg width="112" height="93" viewBox="0 0 112 93" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_520_31192)">
      <circle cx="56" cy="52" r="24" fill="#7C5BF7"  style="pointer-events: fill;"/>
      </g>
      <g clip-path="url(#clip0_520_31192)">
      <path d="M66 64C66 64 62.6329 62.6667 59.9093 61.5994H48.455C47.1 61.5994 46 60.4249 46 58.9781V43.6191C46 42.1737 47.1 41 48.455 41H63.5443C64.9 41 65.9993 42.173 65.9993 43.6198V56.8477L66 64ZM63.0436 55.3879C62.9901 55.3188 62.9229 55.2617 62.8463 55.2201C62.7697 55.1785 62.6854 55.1533 62.5986 55.1462C62.5119 55.1391 62.4246 55.1502 62.3423 55.1789C62.2601 55.2075 62.1846 55.253 62.1207 55.3125C62.0993 55.3312 59.9671 57.2394 55.9993 57.2394C52.0807 57.2394 49.9157 55.3448 49.8771 55.3103C49.8132 55.2511 49.7378 55.2059 49.6556 55.1776C49.5734 55.1492 49.4863 55.1383 49.3997 55.1455C49.3131 55.1527 49.2289 55.178 49.1525 55.2196C49.0761 55.2612 49.0091 55.3182 48.9557 55.3872C48.8414 55.5308 48.7855 55.7127 48.7993 55.8961C48.8114 56.0786 48.8927 56.2495 49.0264 56.3733C49.1279 56.4653 51.5529 58.6374 55.9993 58.6374C60.4464 58.6374 62.8714 56.4653 62.9729 56.3733C63.1063 56.2493 63.1874 56.0785 63.1993 55.8961C63.2131 55.713 63.1575 55.5314 63.0436 55.3879Z" fill="white"/>
      </g>
      <defs>
      <filter id="filter0_d_520_31192" x="0" y="0" width="112" height="112" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="16"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0392157 0 0 0 0 0.0235294 0 0 0 0 0.0901961 0 0 0 0.08 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_520_31192"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_520_31192" result="shape"/>
      </filter>
      <clipPath id="clip0_520_31192">
      <rect width="20" height="23" fill="white" transform="translate(46 41)"/>
      </clipPath>
      </defs>
      </svg>
      
  </div>
</div>

