import { Injectable, OnDestroy } from '@angular/core';
import { AccountService } from '../account/account.service';
import { BehaviorSubject, combineLatest, throwError, ReplaySubject, merge, Subject, Observable } from 'rxjs';
import { catchError, takeUntil, debounceTime, finalize, map, filter } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LangService implements OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private value: BehaviorSubject<string> = new BehaviorSubject(null);
  private pack: BehaviorSubject<any> = new BehaviorSubject(null);
  private list: string[] = ['en', 'ru', 'uk'];

  constructor(
    private account_service: AccountService,
    private http: HttpClient,
  ) {
    this.set();
    
    this.account_service.user_info
    .pipe(
      takeUntil(this.destroyed$),
      filter(data => data !== null)
    )
    .subscribe(data => {
      if (!localStorage.getItem('yoola_app:lang') || data.lang != localStorage.getItem('yoola_app:lang')) {
        localStorage.setItem('yoola_app:lang', data.lang);
      }
      
      this.set();
    });

    this.value
    .pipe(
      takeUntil(this.destroyed$),
      filter(data => data !== null)
    )
    .subscribe(code => {
      this.http.get(`lang/${code}.json`)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(pack => {
        this.pack.next(pack);
      });
    });
  }

  public set(lang_pack: string = null): void {
    const ls = localStorage.getItem('yoola_app:lang');
    const nav = navigator['language'].split('-')[0];
    const lang = (lang_pack) ? lang_pack : (ls && this.list.includes(ls)) 
    ? ls : (nav && this.list.includes(nav)) ? nav : 'en'; 
    this.value.next(lang);
  }

  public get(id: string): any {
    return this.pack
    .pipe(
      takeUntil(this.destroyed$),
      filter(data => data !== null),
      map(pack => pack[id])
    )
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
