import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '../../account.service';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ServiceMessagesService } from 'src/app/shared/service-messages.service';
import { DialogAlertComponent } from 'src/app/shared/components/dialog-alert/dialog-alert.component';
import { LangService } from 'src/app/shared/lang.service';
import { opacityAnimation } from 'src/app/shared/animations/opacity.animation';


@Component({
  selector: 'app-dialog-add-channel',
  templateUrl: './dialog-add-channel.component.html',
  styleUrls: ['./dialog-add-channel.component.scss'],
  animations: [opacityAnimation]
})
export class DialogAddChannelComponent implements OnInit, OnDestroy {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  
  public view = 'main';
  public lang: any = null;
  public link_control: FormControl = new FormControl('', [
    Validators.required, 
    Validators.pattern('^(?!.*[а-яА-Я]).*youtube\\.com.*')
  ]);

  public component_loading = new BehaviorSubject(true);
  public lang_loading = new BehaviorSubject(true);

  constructor(
    private account_service: AccountService,
    public dialogRef: MatDialogRef<DialogAddChannelComponent>,
    private service_messages: ServiceMessagesService,
    private mat_dialog: MatDialog,
    public lang_service: LangService
  ) {
    combineLatest([this.lang_loading])
    .pipe(takeUntil(this.destroyed$), debounceTime(100))
    .subscribe((loading: boolean[]) => {
      this.component_loading.next(loading.reduce((_loading, next) => _loading || next));
    });

    this.lang_service.get('component__account__toolbar__dialog-add-channel')
    .pipe(takeUntil(this.destroyed$))
    .subscribe(lang => {
      this.lang = lang;
      this.lang_loading.next(false);
    });
  }

  ngOnInit(): void {
  }

  send_youtube_link() {
    const data = {yt_url: this.link_control.value}        
    this.account_service.send_youtube_link(data)
    .pipe(takeUntil(this.destroyed$))
    .subscribe(data => {
      this.mat_dialog.open(DialogAlertComponent, {
        width: '460px',
        panelClass: 'dialog-text-center',
        data: {
          title: this.lang?.thank_you_for_your_submission,
          html: this.lang?.thank_you_for_your_interest,
          button: this.lang?.ok_button
        },
      });
      this.service_messages.show({text: this.lang?.ok_we_accepted_your_link});
      this.dialogRef.close(true);
    })
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
