import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogAddChannelComponent } from 'src/app/account/toolbar/dialog-add-channel/dialog-add-channel.component';

@Component({
  selector: 'app-dialog-alert',
  templateUrl: './dialog-alert.component.html',
  styleUrls: ['./dialog-alert.component.scss'],
})
export class DialogAlertComponent implements OnInit {

  public config = null;
  private default_config = {
    title: '',
    html: '',
    button: 'OK!',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogAddChannelComponent>
  ) {
    this.config = Object.assign(this.default_config, data);
  }

  ngOnInit(): void {
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
