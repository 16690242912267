import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { catchError, takeUntil, debounceTime, finalize, map } from 'rxjs/operators';
import { IUserIntercom } from './interfaces/user.interface';
import { Intercom } from 'ng-intercom';

@Injectable({
  providedIn: 'root'
})
export class IntercomService implements OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public user_itercom: BehaviorSubject<IUserIntercom> = new BehaviorSubject(null);

  constructor(
    private intercom: Intercom
  ) {
    this.user_itercom
    .pipe(
      takeUntil(this.destroyed$),
    )
    .subscribe(data => {
      this.intercom.shutdown();
      this.clear_cookies();
      this.intercom.boot({...{
        app_id: environment.INTERCOM_APP_ID, 
        hide_default_launcher: true, 
        widget: {
          activator: '#intercom'
        }}, ...(data) ? data : {}});
    });
  }

  clear_cookies(): void {
    document.cookie.split(";").forEach((c) => {
      if (c.trim().startsWith('intercom')) {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      }
    });
  }

  open(): void {
    this.intercom.show();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
