import { Component } from '@angular/core';
import { IntercomService } from './shared/intercom.service';
import { LangService } from './shared/lang.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private intercom_service: IntercomService,
    private lang_service: LangService
  ) {}
}
