<div class="contaner w-auto tablet-page">

<div *ngIf="data" class="d-flex align-items-center justify-content-between">
    <div style="display: flex; align-items: center;">
        <div style="display: flex; align-items: center; margin: 0">
            <div *ngIf="data.type === 'success'">
                <img src="../../../../assets/img/i_success.svg">
            </div>
            <div *ngIf="data.type === 'warning'">
                <img src="../../../../assets/img/i_warning.svg">
            </div>
            <div *ngIf="data.type === 'error'">
                <img src="../../../../assets/img/i_error.svg">
            </div>
        </div>
        <div>        
            <p style="padding-left: 16px; margin: 0;">{{data.text}}</p>
        </div>
    </div>
    <button class="button-wrapper hover-opacity d-flex align-items-center" style="cursor: pointer;" (click)="close()">
        <mat-icon class="text-muted hover-opacity-el" style="opacity: .65;">close</mat-icon>
    </button>
</div>
</div>
