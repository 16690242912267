import { NgModule, Injectable } from '@angular/core';
import { RouterModule, Routes, CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { GuardService } from './shared/guard.service';
import { AuthService } from './shared/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router, 
    private auth_service: AuthService,
  ) { }  

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (state.root.queryParams?.activation_code_change_email) {
      this.auth_service.set_confirm_mail(state.root.queryParams.activation_code_change_email);
    }

    if (state.root.queryParams.token && state.root.queryParams.version) {
      localStorage.setItem('yoola_app:token', state.root.queryParams.token);
      localStorage.setItem('yoola_app:version', state.root.queryParams.version);
    }

    const can = (localStorage.getItem('yoola_app:token')) ? false : true;
    if (!can) this.router.navigate(['']);
    return can;
  }
}

const routes: Routes = [
  {
    path: 'r/:id', 
    redirectTo: 'auth/signup/:id', 
  },
  {
    path: 'auth', 
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [AuthGuardService], 
  },
  {
    path: 'public', 
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule),
  },
  {
    path: '', 
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    canActivate: [GuardService], 
  },
  {
    path: '**', 
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
